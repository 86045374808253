import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      }
    ]
  },
  {
    path: 'system',
    canActivate: [AuthGuard],
    loadChildren: () => import('./routes/system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], //{ relativeLinkResolution: 'legacy' }
  exports: [RouterModule]
})
export class AppRoutingModule {}
