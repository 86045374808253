<div class="app-menu" (window:resize)="onResize()">
  <mat-sidenav-container class="app-container" autosize>
    <mat-sidenav *ngIf="mobileQuery" #sidenav class="example-sidenav" [class.large-width]="mobileQuery"
      [mode]="mobileQuery ? 'over' : 'side'" [opened]="!mobileQuery" [disableClose]="!mobileQuery">
      <div class="main-logo">
        <div class="logo-container" routerLink="/">
          <img class="img" height="65" width="65" [src]="'assets/imgs/logo.png'" alt="logo-image" />
        </div>
      </div>
      <mat-nav-list class="menu-list mt-0" id="list-nav">
        <div *ngFor="let m of menu">
          <mat-list-item *ngIf="m.child.length <= 0 " [routerLink]="m.route" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact:false}" (click)="m.route ? null : onClickStep()"
            [ngClass]="{'color-blur': !m.route && (routeName !== 'home')}">
            <span class="menu-item-title">{{ m.title | translate}}</span>
          </mat-list-item>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content [class.explicit-sm-ml]="!mobileQuery" [class.explicit-lg-ml]="!mobileQuery">
      <div id="root-main-content" class="example-sidenav-content app-root-content" (scroll)="onScroll($event)">
        <mat-toolbar class="dashboard-toolbar" [class.blurred]="!isFirefox">
          <span class="bg-color" [ngClass]="{'show': enableBgColor}"></span>

          <header class="dashboard__header standard-container" id="root-toolbar">
            <section class="flex items-center gap-x-8">
              <button *ngIf="mobileQuery" class="icon" mat-icon-button (click)="toggleSideNav()">
                <mat-icon svgIcon="menu-hamburger-white"></mat-icon>
              </button>

              <img routerLink="/" class="img" height="58" [src]="'assets/imgs/logo.png'" alt="logo-image" />
              @if (!mobileQuery) {
              <span routerLink="/" class="logo-title">អង្គភាពច្រកចេញចូលតែមួយរដ្ឋបាលរាជធានីភ្នំពេញ</span>
              }
            </section>

            <mat-nav-list class="menu-list mt-0" *ngIf="!mobileQuery">
              <div *ngFor="let m of menu">
                <mat-list-item *ngIf="m.child.length <= 0" [routerLink]="m.route" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact:false}" (click)="m.route ? null : onClickStep()"
                  [ngClass]="{'color-blur': !m.route && (routeName !== 'home')}">
                  <span class="menu-item-title">{{ m.title | translate }}</span>
                </mat-list-item>
              </div>
            </mat-nav-list>

            <div class="header-action">

              <!-- language -->
              <button class="languages" mat-button [matMenuTriggerFor]="dropMenu"
                aria-label="Example icon-button with a menu">
                <mat-icon svgIcon="{{browserLang === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
              </button>
              <mat-menu #dropMenu="matMenu">
                <div>
                  <button mat-menu-item *ngFor="let lang of langs | keyvalue: originalOrder"
                    (click)="useLanguage(lang.key)">
                    <mat-icon svgIcon="{{lang.key === 'en' ? 'english_flag' : 'khmer_flag'}}"></mat-icon>
                    <div class="flex items-center gap-x-8">
                      <span>{{ lang.value }}</span>
                      @if(browserLang === lang.key) {
                      <span class="ml-auto"><mat-icon svgIcon="check"></mat-icon></span>
                      }
                    </div>
                  </button>
                </div>
              </mat-menu>

              <!-- notification -->
              @if(account) {
              <app-notification></app-notification>
              }

              <section class="vertical-line"></section>

              <!-- profile -->
              <div class="avatar">

                @if(account) {
                <button mat-button class="profile-menu-btn" [matMenuTriggerFor]="beforeMenu">
                  <span class="name"> {{(account | staffFullName) || 'Unknown'}}</span>
                  <mat-icon class="arrow-down" iconPositionEnd svgIcon="arrow-down-white"></mat-icon>
                </button>
                }@else {
                @if(mobileQuery) {
                <button mat-icon-button routerLink="{{loginRoute}}" class="login-icon-button">
                  <mat-icon svgIcon="sign_up_color"></mat-icon>
                </button>
                }@else {
                <button mat-flat-button routerLink="{{loginRoute}}" class="login-button">
                  <mat-icon svgIcon="sign_up_color"></mat-icon>
                  <span class="login-text">{{'login_to_use' | translate}}</span>
                </button>
                }
                }

                <mat-menu #beforeMenu="matMenu" xPosition="before" backdropClass="hover-profile">
                  <button mat-menu-item
                    routerLink="{{routeUrl.HOME + '/profile/detail'}}"><mat-icon>person_outline</mat-icon>{{
                    'my_background' | translate }}</button>
                  <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>{{ 'logout' | translate
                    }}</button>
                </mat-menu>
              </div>
            </div>
          </header>
        </mat-toolbar>

        <section class="dash-row" id="root-container"
          [ngStyle]="{'margin-top': (routeName === 'home' ? 0 : toolbarHeight) + 'px'}">
          <router-outlet></router-outlet>
        </section>

        <section class="scroll-to-top" [ngClass]="{ 'show': isScrollToBottom }">
          <button mat-fab color="info" (click)="scrollToTop()">
            <mat-icon>arrow_upward_alt</mat-icon>
          </button>
        </section>

        <ng-container *ngTemplateOutlet="footer"></ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #footer>
  <footer [ngStyle]="{'height': footerHeight + 'px'}">
    <div class="footer-wrapper standard-container" id="footer">
      <div class="flex justify-content-between wrap gap-16 footer-head">
        <section class="footer__logo">
          <div class="flex gap-x-8">
            <img class="img" height="58" [src]="'assets/imgs/logo.png'" alt="logo-image" />

            <div class="grid gap-y-8">
              <span class="logo-title">អង្គភាពច្រកចេញចូលតែមួយ
                រដ្ឋបាលរាជធានីភ្នំពេញ</span>

              <div class="flex items-center gap-8">
                <a href="https://www.facebook.com" target="_blank">
                  <button mat-mini-fab color="base">
                    <mat-icon svgIcon="facebook"></mat-icon>
                  </button>
                </a>
                <a href="https://t.me/" target="_blank">
                  <button mat-mini-fab color="base">
                    <mat-icon svgIcon="telegram"></mat-icon>
                  </button>
                </a>
                <a href="https://www.instagram.com/" target="_blank">
                  <button mat-mini-fab color="base">
                    <mat-icon svgIcon="instagram"></mat-icon>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="footer__partner ml-auto">
          <section class="flex items-center gap-x-16 mb-16">
            <img src="assets/imgs/footer/aba.png" alt="" width="60" height="60">
            <img src="assets/imgs/footer/acleda.png" alt="" width="60" height="60">
            <img src="assets/imgs/footer/bakong.png" alt="" width="60" height="60">
          </section>

          <p align="right">ដៃគូបង់ប្រាក់តាមប្រព័ន្ធអនឡាញ</p>
        </section>
      </div>

      <mat-divider></mat-divider>

      <p class="py-16" align="center">© 2024 All Rights Reserved One Window Service Unit</p>
    </div>
  </footer>
</ng-template>